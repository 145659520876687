import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { FaSpinner, FaSearch } from 'react-icons/fa';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';

import {API_URL} from './Constants';

const Search = () => {
  const settings = useSettings();

  const [data, setData] = useState()
  const [searching, setSearching] = useState(false)
  const [term, setTerm] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    document.title = 'Search Parkers | ValParx'
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    setData('')
    setSearching(true)
    setError('')

    if (!term) {
      setError('Term is required. Please try again.');
      setSearching(false)
      return;
    }

    //console.log('getting data')
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", settings.userToken);

    var formdata = new FormData();
    formdata.append("term", term)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "parkersearch", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 200) {
          setData(result.parkers)
        } else {
          setError(result.message)
        }
        setSearching(false)
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  return (
    <div className='min-h-screen'>
      <Nav back={true} />
      <div className='pt-20 lg:px-10 px-5 max-w-5xl mx-auto'>
        <p className='text-center mb-4 text-2xl font-bold'>Parker Search</p>
        <p className='text-center mb-8'>Use the box below to search for monthly parkers. Once found, tap the monthly parker to enter car details.</p>
        <div className='flex gap-2'>
          <div className='w-11/12'>
            <input
              id="vehicle_color"
              type='text'
              className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
              onChange={(event) => setTerm(event.target.value)}
              onBlur={(event) => setTerm(event.target.value)}
              editable="true"
              value={term}
              autoCorrect="false"
            />
          </div>
          {searching ? (
            <div className='w-1/12 flex items-center justify-center bg-primary p-2 rounded-full'>
              <FaSpinner className="text-white animate-spin text-base" />
            </div>
          ) : (
            <a onClick={handleSearch} className='w-1/12 p-2 rounded-full bg-primary flex items-center justify-center'>
              <FaSearch className='text-white text-base' />
            </a>
          )}
        </div>

        <div className='max-w-2xl mx-auto gap-2 pt-10'>
          {error && (
            <p className='text-center text-red-500 font-bold'>{error}</p>
          )}

          {data && (
            <div>
              {data.map((parker) => (
                <Link to={"/parker/"+parker.id} key={parker.id}>
                  <div className='flex flex-row items-center justify-between p-4 mb-4 rounded-lg bg-gray-200'>
                    <div className='text-left text-lg md:text-base'>{parker.name}</div>
                    <div className='text-center text-lg md:text-base px-5'>{parker.tag}</div>
                    <div className='text-right text-lg md:text-base'>{parker.title}</div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Search