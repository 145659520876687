import React, { useEffect, useState } from 'react';
import { FaSpinner } from "react-icons/fa";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Switch } from "@material-tailwind/react";

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';
import { API_URL } from './Constants';
import Loading from './partials/Loading';

const Add = () => {
  const { id, transactionId, userId } = useParams();
  const settings = useSettings();
  const token = settings.userToken
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false)
  const [hangTag, setHangTag] = useState()
  const [customerName, setCustomerName] = useState()
  const [customerPhone, setCustomerPhone] = useState()
  const [customerEmail, setCustomerEmail] = useState()
  const [vehicleMake, setVehicleMake] = useState()
  const [vehiclePlate, setVehiclePlate] = useState()
  const [vehicleColor, setVehicleColor] = useState()
  const [lot, setLot] = useState()
  const [loadingTransaction, setLoadingTransaction] = useState(false)
  const [status, setStatus] = useState(-1)
  const [driver, setDriver] = useState('')
  
  // Get the user's name from the settings context
  useEffect(() => {
    if (settings && settings.userName) {
      setDriver(settings.userName);
    }
  }, [settings]);
  //const [cash, setCash] = useState(false)
  const [stallNumber, setStallNumber] = useState('')
  const [parkerHangtag, setParkerHangtag] = useState()
  const [paymentMethod, setPaymentMethod] = useState('')
  const [paymentRequired, setPaymentRequired] = useState(false)

  useEffect(() => {
    if (id) {
      var myHeaders = new Headers();
      myHeaders.append("vp-session-token", token);

      var formdata = new FormData();
      formdata.append("id", id);

      if (userId) {
        formdata.append("userId", userId);
      }

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "getlots", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)

          if (result.code == 200) {
            setLot(result.lot)

            if (result.user) {
              if (result.user.hangtag) {
                setParkerHangtag(result.user.hangtag)
              }
              if (result.user.tag) {
                setVehiclePlate(result.user.tag)
              }
              if (result.user.name) {
                setCustomerName(result.user.name)
              }
              
              // Set additional parker data if available
              if (result.user.customer_name) {
                setCustomerName(result.user.customer_name)
              }
              if (result.user.customer_phone) {
                setCustomerPhone(result.user.customer_phone)
              }
              if (result.user.customer_email) {
                setCustomerEmail(result.user.customer_email)
              }
              if (result.user.vehicle_make) {
                setVehicleMake(result.user.vehicle_make)
              }
              if (result.user.vehicle_color) {
                setVehicleColor(result.user.vehicle_color)
              }

              setPaymentMethod(2)
            }
          }
        })
        .catch(error => console.log('error', error));
    }
    
    // transaction edit
    if (transactionId && transactionId !== 0) {
      //console.log('transactionId', transactionId)
      setLoadingTransaction(true)

      var myHeaders = new Headers();
      myHeaders.append("vp-session-token", token);

      var formdata = new FormData();
      formdata.append("id", transactionId);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "gettransaction", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)
          if (result.code == 200) {
            setHangTag(result.transaction.key_tag)
            setCustomerName(result.transaction.customer_name)
            setCustomerEmail(result.transaction.customer_email)
            setCustomerPhone(result.transaction.customer_phone)
            setVehicleColor(result.transaction.vehicle_color)
            setVehicleMake(result.transaction.vehicle_make)
            setVehiclePlate(result.transaction.vehicle_plate)
            setStatus(result.transaction.status)
            setParkerHangtag(result.parker_hangtag)
            setPaymentMethod(result.payment_method)

            setLot(result.lot)
          } else {
            alert(result.message);
          }

          setLoadingTransaction(false)
        })
        .catch(error => console.log('error', error));
    }
  }, [])

  /**
   * 
   * @returns 
   */
  const handleSave = () => {
    setIsSaving(true)

    if (!hangTag) {
      setIsSaving(false)
      alert('Hang tag number is required. Please try again.');
      return;
    }

    if (!customerPhone) {
      setIsSaving(false)
      alert('Customer phone number is required. Please provide a phone number that can receive text messages and try again.');
      return;
    }

    //save data
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("key_tag", hangTag);
    formdata.append("lot", lot.id);
    formdata.append("charge_amount", lot.charge_amount);
    formdata.append("fee_collected", lot.fee);
    formdata.append("driver", driver);
    formdata.append("stall_number", stallNumber);
  
    if (transactionId) {
      formdata.append("transaction_id", transactionId);
      if (status) {
        formdata.append("status", status);
      }
    }

    if (customerEmail) {
      formdata.append("customer_email", customerEmail);
    }
    if (customerPhone) {
      formdata.append("customer_phone", customerPhone);
    }
    if (vehicleColor) {
      formdata.append("vehicle_color", vehicleColor);
    }
    if (vehiclePlate) {
      formdata.append("vehicle_plate", vehiclePlate);
    }
    if (vehicleMake) {
      formdata.append("vehicle_make", vehicleMake);
    }
    if (customerName) {
      formdata.append("customer_name", customerName);
    }

    formdata.set('payment_method', paymentMethod)
    if (paymentMethod == 1 || paymentMethod == 3) {
      //formdata.append("cash", 1)
      formdata.set("fee_collected", 0.0);
      formdata.set("status", 2);
    }
    if (parkerHangtag) {
      formdata.append("parker_hangtag", parkerHangtag);
      formdata.set("fee_collected", 0.0);
      formdata.set("status", 2);
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "addparking", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 200) {
          navigate('/lot/'+lot.id);
        } else {
          alert(result.message);
        }
        setIsSaving(false)
      })
      .catch(error => {
        //console.log('error', error)
        alert('There was an unexpected error. '+error+' Please try again.');
      });
  }

  const parseStatus = () => {
    if (status == 1) {
      return 'Open'
    }

    if (status == 0) {
      return 'Closed'
    }

    if (status == 2) {
      return 'Paid'
    }

    if (status == 3) {
      return 'Ready for pickup'
    }
  }

  const handlePickerSelect = (event) => {
    if (event.target.value) {
      setStatus(event.target.value);
    }
  };

  const handlePickerPayment = (event) => {
    if (event.target.value) {
      setPaymentMethod(event.target.value);
    }
  }

  return (
    <div className='h-full flex bg-primary w-full'>
      <div className='flex-1 bg-white w-full'>
        <Nav back={true}>Add Valet Parking</Nav>
        
        {loadingTransaction ? (
          <Loading />
        ) : (
          <>
            <div className='flex-1 w-full py-12 mx-auto max-w-3xl'>
              <div className='p-5 w-full'>
                {parkerHangtag && (
                  <div className='mx-auto max-w-2xl w-full mb-10'>
                    <p className='text-center font-bold'>Note: Monthly Parker data has been auto filled.</p>
                  </div>
                )}

                <p className='uppercase text-lg lg:text-2xl md:text-base font-bold text-primary mx-7 mb-2 text-center mt-5'>Valet Parking Information</p>
                <div className='w-full p-2 px-5 border-t border-solid border-primary'>
                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base md:text-sm'>Valet Hang Tag Number *</p>
                    <input
                      id="hangtag"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-base md:text-sm w-full'
                      onChange={(event) => {
                        const value = event.target.value;
                        setHangTag(value);
                        
                        // Check if hangtag is in the restricted range (3500-3599)
                        if (value) {
                          const numValue = parseInt(value);
                          if (numValue >= 3500 && numValue <= 3599) {
                            setPaymentRequired(true);
                            // Set payment method to Credit/Validation if it's currently set to No Charge
                            if (paymentMethod === "3") {
                              setPaymentMethod("0");
                            }
                          } else {
                            setPaymentRequired(false);
                          }
                        } else {
                          setPaymentRequired(false);
                        }
                      }}
                      onBlur={(event) => {
                        const value = event.target.value;
                        setHangTag(value);
                        
                        // Check if hangtag is in the restricted range (3500-3599)
                        if (value) {
                          const numValue = parseInt(value);
                          if (numValue >= 3500 && numValue <= 3599) {
                            setPaymentRequired(true);
                            // Set payment method to Credit/Validation if it's currently set to No Charge
                            if (paymentMethod === "3") {
                              setPaymentMethod("0");
                            }
                          } else {
                            setPaymentRequired(false);
                          }
                        } else {
                          setPaymentRequired(false);
                        }
                      }}
                      editable="true"
                      value={hangTag}
                      autoCorrect="false"
                    />
                  </div>

                  <div className=' mb-8 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base'>Valet Driver</p>
                    <input
                      id="driver"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                      onChange={(event) => setDriver(event.target.value)}
                      onBlur={(event) => setDriver(event.target.value)}
                      editable="true"
                      value={driver}
                      autoCorrect="false"
                    />
                  </div>

                  <div className=' mb-8 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base'>Valet Parking Stall Number {id && (<span className='text-sm'>(Can be added later)</span>)}</p>
                    <input
                      id="stall_number"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                      onChange={(event) => setStallNumber(event.target.value)}
                      onBlur={(event) => setStallNumber(event.target.value)}
                      editable="true"
                      value={stallNumber}
                      autoCorrect="false"
                    />
                  </div>

                  {!parkerHangtag ? (
                    <div className=' mb-8 mt-3'>
                      <p className='mr-2 mb-3 lg:text-xl text-base'>Payment Method</p>
                      <p className='mr-2 mb-3 lg:text-sm text-xs'>If validation code, Customer will be prompted to enter code when they request their car.</p>
                      <select className='w-full border-darkgrey border-solid border rounded p-2' value={paymentMethod} onChange={handlePickerPayment}>
                        <option value="">Select...</option>
                        <option value="0">Credit / Validation Code (from business)</option>
                        <option value="1">Cash</option>
                        {!paymentRequired && (
                          <option value="3">No Charge</option>
                        )}
                      </select>
                      {paymentRequired && (
                        <p className='mr-2 mt-3 lg:text-sm text-xs text-red-500 font-bold text-center'>
                          Payment is required for hangtag numbers between 3500-3599. "No Charge" option is not available.
                        </p>
                      )}
                    </div>
                  ) : (
                    <p className='mr-2 mb-3 lg:text-xl text-base'></p>
                  )}
                 
                  { /** 
                    <div className=' mb-4 mt-3 flex items-center gap-5'>
                      <p className='mb-0 lg:text-xl text-base'>Is the customer paying cash?</p>
                      <div className='flex justify-center items-center gap-5'>
                        No
                        <Switch
                          id="custom-switch-component"
                          ripple={false}
                          className="h-full w-full checked:bg-[#2ec946]"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                          checked={cash}
                          onChange={() => {setCash(!cash)}}
                        />
                        Yes
                      </div>
                    </div>
                  */ }
                </div>

                <p className='uppercase text-lg lg:text-2xl md:text-base font-bold text-primary mx-7 mb-2 text-center mt-5'>Customer Information</p>
                <div className='w-full p-2 px-5 border-t border-solid border-primary'>
                  {parkerHangtag && (
                    <div className=' mb-8 mt-3'>
                      <p className='mr-2 mb-3 lg:text-xl text-base'>Monthly Parker Hangtag Number*</p>
                      <input
                        id="parker_hangtag"
                        type='text'
                        className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                        onChange={(event) => setParkerHangtag(event.target.value)}
                        onBlur={(event) => setParkerHangtag(event.target.value)}
                        editable="true"
                        value={parkerHangtag}
                        autoCorrect="false"
                      />
                    </div>
                  )}
                  
                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base md:text-sm'>Customer Name</p>
                    <input
                      id="name"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-base md:text-sm w-full'
                      onChange={(event) => setCustomerName(event.target.value)}
                      onBlur={(event) => setCustomerName(event.target.value)}
                      editable="true"
                      value={customerName}
                      autoCorrect="false"
                    />
                  </div>

                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-1 lg:text-xl text-base'>Customer Phone *</p>
                    <p className='mr-2 mb-3 lg:text-sm text-xs'>Only a mobile number that can receive SMS text messages.</p>
                    <input
                      id="phone"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-base md:text-sm w-full'
                      onChange={(event) => setCustomerPhone(event.target.value)}
                      onBlur={(event) => setCustomerPhone(event.target.value)}
                      editable="true"
                      value={customerPhone}
                      autoCorrect="false"
                    />
                  </div>

                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base'>Customer Email</p>
                    <input
                      id="email"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                      onChange={(event) => setCustomerEmail(event.target.value)}
                      onBlur={(event) => setCustomerEmail(event.target.value)}
                      editable="true"
                      value={customerEmail}
                      autoCorrect="false"
                    />
                  </div>
                </div>
                  
                <p className='uppercase text-lg lg:text-2xl md:text-base font-bold text-primary mx-7 mb-2 text-center mt-5'>Vehicle Information</p>
                <div className='w-full p-2 px-5 border-t border-solid border-primary'>
                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base'>Vehicle Make</p>
                    <input
                      id="vehicle_make"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                      onChange={(event) => setVehicleMake(event.target.value)}
                      onBlur={(event) => setVehicleMake(event.target.value)}
                      editable="true"
                      value={vehicleMake}
                      autoCorrect="false"
                    />
                  </div>

                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base'>Vehicle Plate</p>
                    <input
                      id="vehicle_plate"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                      onChange={(event) => setVehiclePlate(event.target.value)}
                      onBlur={(event) => setVehiclePlate(event.target.value)}
                      editable="true"
                      value={vehiclePlate}
                      autoCorrect="false"
                    />
                  </div>

                  <div className=' mb-4 mt-3'>
                    <p className='mr-2 mb-3 lg:text-xl text-base'>Vehicle Color</p>
                    <input
                      id="vehicle_color"
                      type='text'
                      className='border border-solid border-gray-500 rounded p-2 lg:text-xl text-sm w-full'
                      onChange={(event) => setVehicleColor(event.target.value)}
                      onBlur={(event) => setVehicleColor(event.target.value)}
                      editable="true"
                      value={vehicleColor}
                      autoCorrect="false"
                    />
                  </div>
                </div>

                {transactionId && (
                  <>
                    <p className='uppercase text-lg lg:text-2xl md:text-base font-bold text-primary mx-7 mb-2 text-center mt-5'>Transaction Status</p>
                    <div className='w-full p-5 border-t border-solid border-primary'>
                      <select className='w-full border-darkgrey border-solid border rounded p-2' value={status} onChange={handlePickerSelect}>
                        <option value="">Select...</option>
                        <option value="1">Open</option>
                        <option value="2">Paid</option>
                        <option value="3">Ready for pickup</option>
                        <option value="4">Close</option>
                      </select>
                    </div>
                  </>
                )}
                
              </div>
            </div>

            <div className='flex bg-primary w-full p-2 py-4'>
              <div className='mx-auto max-w-3xl w-full flex'>
                {isSaving ? (
                  <div className='flex items-center justify-center w-full py-2 px-4 bg-darkgrey rounded-3xl' >
                    <FaSpinner className="text-white fa-spin" />
                  </div>
                ) : (
                  <a className='flex items-center justify-center w-full py-3 px-4 bg-darkgrey rounded-3xl' onClick={handleSave}>
                    <p className='text-center text-white text-xl font-bold uppercase mb-0'>{transactionId ? 'Update' : 'Add'}</p>
                  </a>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Add;
